import React from 'react';
import { Link } from 'gatsby';
 
export const SloganBlock = ({
    title, 
    description
}) => {
    return (
        <>
			<section id="about" className="feature major">
				{/* <span className="icon alt d-inline-block solid">
                    <img src="/img/avayly-2.png" alt="AVAYLY" className="logo" />
                </span> */}
				<h2>{title}</h2>
				<p>{description}</p>
                <Link to={'/about-us'} class={'button'}>Learn more</Link>
			</section>
        </>
    );
};