class Utility {
    
    /* breakpoints((
		xlarge:   ( 1281px,  1680px ),
		large:    ( 981px,   1280px ),
		medium:   ( 737px,   980px  ),
		small:    ( 481px,   736px  ),
		xsmall:   ( null,    480px  )
    ));
    */

    getHeaderOffset() {
        let offset = 0;
        if (typeof window !== "undefined") {
            offset = window.innerWidth > 1280 ? 56 : 
                window.innerWidth > 980 ? 56 : 
                    window.innerWidth > 736 ? 45 :
                        window.innerWidth > 480 ? 45 : 41;
        }
        return offset;
    }
}

export default new Utility();