import React from 'react'
import BackgroundImage from 'gatsby-background-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Utility from '../../helpers/Utility';

export const HeaderHero = (props) => {
    return (
    <BackgroundImage
        Tag="section"
        id={`banner`}
        className={`header-hero`}
        fluid={!!props.heroImage && !!props.heroImage.childImageSharp && props.heroImage.childImageSharp.fluid}
        fadeIn={`soft`}
    >
        <div className={`before`}> </div>
        <div className="inner">
            <h2>{props.title}</h2>
            {/* <h3>{props.subheading}</h3> */}
            <ul className="actions special">
                <li><AnchorLink offset={Utility.getHeaderOffset()} href="#contact" className="button large primary">Get in touch with us</AnchorLink></li>
                {/*<li><AnchorLink offset={Utility.getHeaderOffset()} href="#contact" className="button large">Free website report</AnchorLink></li>*/}
            </ul>
        </div>
        <div className={`overlay`}> </div>
        <AnchorLink href="#about" offset={Utility.getHeaderOffset()} className="more scrolly">Learn more</AnchorLink>
    </BackgroundImage>
    );
};
