import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
// import Expertises from '../components/Expertises';
import ContactForm from '../components/forms/ContactForm';
import { SloganBlock } from '../components/SloganBlock';
// import  Highlight  from '../components/Highlight';
import Usps from '../components/Usps';
import { ServicesBlock } from '../components/ServicesBlock';

export const IndexPageTemplate = ({
  image,
  title,
  subheading,
  sloganblock,
  usps,
  intro,
  action,
}) => (
  <>
	  <div id="main" className="container">
      <SloganBlock title={sloganblock.title} description={sloganblock.description}/>
      <Usps usps={usps} />
      <ServicesBlock />
      {/* <section id="expertise" className="feature">
						<div className="row">
							<div className="col-4 col-12-medium">
                <Highlight heading={intro.heading} description={intro.description} 
                  buttonText={intro.buttonText} buttonUrl={intro.buttonUrl} />
							</div>
              <div className="col-8 col-12-medium">
                <Expertises gridItems={intro.blurbs} />
              </div>
						</div>
		  </section> */}

      {/* temporary disabled due to lack of content 
      <ActionBlock title={action.title} description={action.description} 
        buttonText={action.buttonText} buttonUrl={action.buttonUrl} />
      */}
      <ContactForm />
    </div>
  </>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  sloganblock: PropTypes.object,
  usps: PropTypes.array,
  action: PropTypes.object,
  intro: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        sloganblock={frontmatter.sloganblock}
        usps={frontmatter.usps}
        action={frontmatter.action}
        intro={frontmatter.intro}
      />
    </Layout>
  )
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subheading
        sloganblock {
          title
          description
        }
        usps {
          text
          title
        }
        action {
          title
          description
          buttonText
          buttonUrl
        }
        intro {
          blurbs {
            class
            text
          }
          heading
          description
          buttonText
          buttonUrl
        }
      }
    }
  }
`;