import React from 'react';
import { Link } from 'gatsby';
import { HeaderHero } from "./HeaderHero";
import MainMenu from "./MainMenu";

const Header = class extends React.Component {
  render = () => (
    <>
        <header id="header">
            <h1>
                <Link to="/">
                    <img className="logo" src="/img/avayly-2.png" alt="AVAYLY logo" />
                </Link>
            </h1>
            <MainMenu />
        </header>
        <HeaderHero heroImage={this.props.heroImage} title={this.props.title} subheading={this.props.subheading}/>
    </>
  )
};

export default Header;
