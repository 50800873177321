import React from 'react'
import {graphql, StaticQuery} from "gatsby";
import { Link } from 'gatsby';

const Footer = (data) => (
  <>
    <footer id="footer">
    { data.data.allMarkdownRemark.edges !== undefined && data.data.allMarkdownRemark.edges.length > 0 &&
      <div className="copyright container">
        <p>{data.data.allMarkdownRemark.edges[0].node.frontmatter.servicemenu.serviceText}</p>
        <ul className="menu">
        { data.data.allMarkdownRemark.edges[0].node.frontmatter.servicemenu.menuItems.map((menuItem, i) => {
            return (
              <li key={i}><Link to={menuItem.url}>{menuItem.title}</Link></li>
          )
        })}
        </ul>
      </div>
    }
    </footer>
  </>
);

export default () => (
    <StaticQuery
        query={graphql`
          query ServiceMenuQuery {
            allMarkdownRemark (filter: {frontmatter: {templateKey: {eq: "menu-items"}}}) {
              edges{
                node{
                  frontmatter{
                    templateKey
                    servicemenu {
                      serviceText
                      menuItems{
                        title
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => <Footer data={data} />}
    />
);