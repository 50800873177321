import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../components/Footer';
import Header from './header/Header';
import './assets/sass/main.scss';
import useSiteMetadata from './SiteMetadata';
import CookieWall from './layout/CookieWall';

const TemplateWrapper = ({ children }) => {

  const { title, description, keywords } = useSiteMetadata();
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/avayly.png" />
      </Helmet>
      <Header heroImage={!!children.props && children.props.image} title={!!children.props && children.props.title} subheading={!!children.props && children.props.subheading} />
      {children}
      <Footer />
      <CookieWall />
    </>
  )
};

export default TemplateWrapper
