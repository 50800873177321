import React from 'react'
import {graphql, StaticQuery} from "gatsby";
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Utility from '../../helpers/Utility';
import { Location, navigate } from '@reach/router'

const MainMenu = ({data}) => (
    <>
      <Location>
        {
          ({location}) => {
            return (
            <>
            { data.allMarkdownRemark.edges !== undefined && data.allMarkdownRemark.edges.length > 0 &&
              <nav id="nav">
                  <ul>
                  { data.allMarkdownRemark.edges[0].node.frontmatter.mainmenu.menuItems.map((menuItem, i) => {
                      return (menuItem.url.indexOf('#') !== -1 ? 
                        (location.pathname === '/' ? 
                          <li key={i}><AnchorLink offset={Utility.getHeaderOffset()} href={menuItem.url}>{menuItem.title}</AnchorLink></li> :
                          <li key={i}><a onClick={() => (navigate('/' + menuItem.url))} href={'/' + menuItem.url}>{menuItem.title}</a></li>) :
                        <li key={i}><Link to={menuItem.url}>{menuItem.title}</Link></li>
                      );
                  })}
                  </ul>
              </nav>
              }
            </>
            )
          }
        }
      </Location>
    </>
);

export default () => (
    <StaticQuery
        query={graphql`
          query MainMenuQuery {
            allMarkdownRemark (filter: {frontmatter: {templateKey: {eq: "menu-items"}}}) {
              edges{
                node{
                  frontmatter{
                    templateKey
                    mainmenu {
                      menuItems{
                        title
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            <MainMenu data={data} />
          </>
        )}
    />
);